import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';

import finanacesReward from 'img/blog/other/financesonline.png';

const Content = () => {
  return (
    <div>
      <p>
        LiveSession Wins Premium Usability Honor for Marketing Software from Platform for Software
        Reviews.
      </p>
      <p>
        LiveSession, helping many businesses accelerate their business growth through better
        knowledge of customer behavior, has recently been conferred with the Premium Usability honor
        for 2019.
      </p>
      <p className="mb-4">
        {' '}
        <ImageModal
          src={finanacesReward}
          alt="award"
          title="Rising star and premium usability award"
        />{' '}
      </p>
      <p>
        LiveSession received the award from{' '}
        <a rel="nofollow" href="https://financesonline.com/">
          FinancesOnline
        </a>
        , a popular software review platform, in recognition of its intuitive set of features for
        analyzing essential customer data. Among the functionalities commended by the FinancesOnline
        review team include “total visibility and transparency” on customer interactions,
        identification of the type of content that positively or negatively impacted customer
        interactions with your website, and data segmentation, among others. And with our
        Straightforward{' '}
        <a rel="nofollow" href="https://reviews.financesonline.com/p/livesession/">
          LiveSession pricing
        </a>{' '}
        options, you can get every single feature that we offer across all plans.{' '}
      </p>

      <p>
        As stated by FinancesOnline, our customer analytics tool is able to document “all sorts of
        data” and “thoroughly” analyze and evaluate these information to produce “highly actionable
        insights.” All in all, this can help businesses provide a more delightful customer journey
        while improving their conversions, stated the review team. And as a testimony on the
        reliability of LiveSession, FinancesOnline certified us with the Rising Star award for 2019
        while being recognized as among the{' '}
        <a
          rel="nofollow"
          href="https://financesonline.com/top-10-alternatives-to-pardot-leading-marketing-software-solutions/"
        >
          leading marketing alternatives
        </a>
        . Furthermore, we also ranked among their top list of{' '}
        <a
          rel="nofollow"
          href="https://financesonline.com/15-best-marketing-software-systems-business/"
        >
          marketing software
        </a>{' '}
        and garnered a 97% customer satisfaction score based on their unique algorithm.{' '}
      </p>

      <p>
        We would like to thank our sincere appreciation to FinancesOnline and all our customers for
        the positive feedback. Stay tuned for better experiences to come as we have a lot of new
        features and quality of life improvements in the pipeline for 2019.{' '}
      </p>
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'LiveSession wins Premium Usability Honor',
  url: `${process.env.GATSBY_HOST}/blog/livesession-wins-premium-usability-honor/`,
  description:
    'LiveSession received the award from FinancesOnline, a popular software review platform.',
  author,
  img: finanacesReward,
  date: '2019-04-17',
  category: '',
  group: 'Awards',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
  />
);
